.modal :global(.ant-modal-content) {
  padding: 0px;
  height: 100%;
}

.modal :global(.ant-modal-body) {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal :global(.ant-modal-body img) {
  max-width: 70%;
  max-height: 70%;
}

.modal {
  height: 500px;
  height: 500px;
}